import React, { useState } from 'react';
import { Route, Routes } from 'react-router';
import SidebarContent from './layout/SidebarContent';
import StakingModal from './layout/StakingModal';
import Pool from './Pool';
import Header from './shared/Header';
import Sidebar from './shared/Sidebar';
import Staking from './Staking';
import Swap from './Swap';
import Trades from './Trades';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IDO from './IDO';

export default function Body() {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {/* <Staking /> */}
      <Header setOpen={setOpen} />

      <div className="flex">
        <div className="hidden md:flex">
          <SidebarContent setOpen={setOpen} />
        </div>
        <div className="flex md:hidden">
          <Sidebar open={open} setOpen={setOpen} />
        </div>
        <Routes>
          <Route path="/staking" element={<Staking />} />
          <Route path="/Swap" element={<Swap />} />
          <Route path="/modal" element={<StakingModal />} />
          <Route path="/pool" element={<Pool />} />
          <Route path="/" element={<Trades />} />
          <Route path="/buytoken" element={<IDO />} />
        </Routes>
      </div>
      <ToastContainer
        position="bottom-left"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
