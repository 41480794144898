import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Trade } from '../../assets/trade.svg';
import { ReactComponent as Swap } from '../../assets/swap.svg';
import { ReactComponent as IDO } from '../../assets/ido.svg';
import { ReactComponent as Staking } from '../../assets/staking.svg';
import { ReactComponent as Pool } from '../../assets/pool.svg';
import { ReactComponent as Farm } from '../../assets/farm.svg';
import { ReactComponent as Docs } from '../../assets/docs.svg';
import { ReactComponent as Community } from '../../assets/community.svg';
import { ReactComponent as Setting } from '../../assets/setting.svg';
import { ReactComponent as Feedback } from '../../assets/feedback.svg';
import { ReactComponent as WalletIcon } from '../../assets/carbon_wallet.svg';
import { ReactComponent as Blockchain } from '../../assets/blockchain.svg';
import { ReactComponent as Nft } from '../../assets/nft.svg';
import { ReactComponent as Metaverse } from '../../assets/metaverse.svg';
import { ReactComponent as Dollar } from '../../assets/dollar_icon.svg';

import { useLocation } from 'react-router-dom';

export default function SidebarContent({ setOpen }) {
  let location = useLocation();
  location = location.pathname.replace('/', '').toLowerCase();

  const [active, setActive] = useState(location || 'trade');

  return (
    <div className="scrollbar-hidden h-[calc(100vh-84px)] min-w-[250px] overflow-y-scroll px-5 pt-4 focus-visible:outline-none sm:pt-8">
      <Link to="/">
        <div
          className={`mb-2 flex cursor-pointer items-center gap-3 rounded-xl px-3 py-4 text-black
      ${
        active === 'trade'
          ? 'bg-primary-sunny-lightBg dark:bg-primary-sunny-blurSemiDarkBg dark:text-primary-sunny-main'
          : 'text-light-text dark:text-dark-text'
      }`}
          onClick={() => {
            setActive('trade');
            setOpen(false);
          }}
        >
          <Trade className="w-6" />
          <div>Trade</div>
        </div>
      </Link>

      <Link to="/Swap">
        <div
          className={`mb-2 flex cursor-pointer items-center gap-3 rounded-xl px-3 py-4 text-black
      ${
        active === 'swap'
          ? 'bg-primary-sunny-lightBg dark:bg-primary-sunny-blurSemiDarkBg dark:text-primary-sunny-main'
          : 'text-light-text dark:text-dark-text'
      }`}
          onClick={() => {
            setActive('swap');
            setOpen(false);
          }}
        >
          <Swap className="w-6" />
          <div>Swap</div>
        </div>
      </Link>

      <Link to="/pool">
        <div
          className={`mb-2 flex cursor-pointer items-center gap-3 rounded-xl px-3 py-4 text-black
      ${
        active === 'pool'
          ? 'bg-primary-sunny-lightBg dark:bg-primary-sunny-blurSemiDarkBg dark:text-primary-sunny-main'
          : 'text-light-text dark:text-dark-text'
      }`}
          onClick={() => {
            setActive('pool');
            setOpen(false);
          }}
        >
          <Pool className="w-6" />

          <div>Pool</div>
        </div>
      </Link>
      <Link to="/staking">
        <div
          className={`mb-2 flex cursor-pointer items-center gap-3 rounded-xl px-3 py-4 text-black
      ${
        active === 'staking'
          ? 'bg-primary-sunny-lightBg dark:bg-primary-sunny-blurSemiDarkBg dark:text-primary-sunny-main'
          : 'text-light-text dark:text-dark-text'
      }`}
          onClick={() => {
            setActive('staking');
            setOpen(false);
          }}
        >
          <Staking />
          <div>Staking</div>
        </div>
      </Link>
      {/* <Link to="/buytoken">
        <div
          className={`mb-2 flex cursor-pointer items-center gap-3 rounded-xl px-3 py-4 text-black
      ${
        active === 'buytoken'
          ? 'bg-primary-sunny-lightBg dark:bg-primary-sunny-blurSemiDarkBg dark:text-primary-sunny-main'
          : 'text-light-text dark:text-dark-text'
      }`}
          onClick={() => {
            setActive('buytoken');
            setOpen(false);
          }}
        >
          <Dollar />
          <div>Buy Token</div>
        </div>
      </Link> */}

      {/* <div className="mb-2 flex items-center justify-between gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
        <WalletIcon className="w-6" />
        <div className="text-light-text">Wallet</div>
        <div className="rounded-full bg-[#4E4E4E] px-1.5 py-1 font-clashdisplay text-sm text-dark-text sm:text-[13px]">
          Coming Soon
        </div>
      </div>
      <div className="mb-2 flex items-center justify-between gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
        <Blockchain className="w-6" />
        <div className="text-light-text">BlockChain</div>
        <div className="rounded-full bg-[#4E4E4E] px-1.5 py-1 font-clashdisplay text-sm text-dark-text sm:text-[13px]">
          Coming Soon
        </div>
      </div>
      <div className="mb-2 flex items-center justify-between gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
        <Nft className="w-6" />
        <div className="text-light-text">Nft</div>
        <div className="rounded-full bg-[#4E4E4E] px-1.5 py-1 font-clashdisplay text-sm text-dark-text sm:text-[13px]">
          Coming Soon
        </div>
      </div>
      <div className="mb-2 flex items-center justify-between gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
        <Metaverse className="w-6" />
        <div className="text-light-text">Metaverse</div>
        <div className="rounded-full bg-[#4E4E4E] px-1.5 py-1 font-clashdisplay text-sm text-dark-text sm:text-[13px]">
          Coming Soon
        </div>
      </div> */}
      <div className="my-10 h-[1px] w-full border-[1px] border-[rgba(194,194,194,0.26)]"></div>
      <a href="https://www.beetrade.net/" target="_blank" rel="noreferrer">
        <div className="mb-2 flex items-center gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
          <Docs />
          <div>Website</div>
        </div>
      </a>
      <a href="https://t.me/testTelegram" target="_blank" rel="noreferrer">
        <div className="mb-2 flex items-center gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
          <Community />
          <div>Community</div>
        </div>
      </a>
      {/* <div className="mb-2 flex items-center gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
        <Setting />
        <div>Settings</div>
      </div>
      <div className="mb-2 flex items-center gap-3 rounded-xl px-3 py-4 text-light-text dark:text-dark-text">
        <Feedback />
        <div>Feedback</div>
      </div> */}
    </div>
  );
}
